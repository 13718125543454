<template>
  <div class="Pass">
    <div class="board-title">9610申报关口<span class="top">TOP10</span></div>
    <div class="Pass-table">
      <DataTable
        :columns="columns"
        :tableData="tableData"
        :visibleSize="3"
        :height="180"
      />
      <!-- <el-table
        :data="tableData"
        :highlight-current-row="false"
        size="small"
      >
        <el-table-column
          prop="portName"
          label="申报关口">
        </el-table-column>
        <el-table-column
          prop="num"
          label="申报单量">
        </el-table-column>
        <el-table-column
          prop="usd"
          label="出口（日元）">
        </el-table-column>
        <el-table-column
          prop="jpe"
          label="出口（美元）">
        </el-table-column>
      </el-table> -->
    </div>
  </div>
</template>

<script>
  import DataTable from '../DataTable.vue';
  export default {
    name: 'Pass',
    props: {
      queryDate: {
        type: Object,
        required: true,
      },
    },
    components: {
      DataTable,
    },
    data() {
      return {
        columns: [
          { label: '申报关口', prop: 'portName' },
          { label: '申报单量', prop: 'num' },
          { label: '出口（美元/万元）', prop: 'usd', key: 'price'},
          { label: '出口（日元/万元）', prop: 'jpe', key: 'price' },
        ],
        tableData: []
      }
    },
    mounted () {
      this.getPortData();
    },
    methods: {
      async getPortData() {
        const resPort = await this.$request.get('/data/portBusinessData/overallQuery', { params: this.queryDate});
        if(resPort.data) {
          this.tableData = resPort.data.map(item =>({
            portName: item.portName,
            num: item.numList ? item.numList.filter(item => item.tradeMode === "9610").reduce((prev, cur) => { prev += cur.num; return prev; }, 0) : '',
            usd: item.priceList ? item.priceList
              .filter(item => (item.tradeMode === "9610" && item.currency === '美元'))
              .reduce((prev, cur) => { prev += cur.totalPrice  / 10000; return prev; }, 0) : '',
            jpe: item.priceList ? item.priceList
              .filter(item => (item.tradeMode === "9610" && item.currency === '日本元'))
              .reduce((prev, cur) => { prev += cur.totalPrice  / 10000; return prev; }, 0) : '',
          }))
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.Pass{
  display: flex;
  flex-direction: column;
  .board-title{
    background-position: 30% 47%;
    .top{
      font-size: 18px;
      font-weight: 700;
      color: #E1B31F;
    }
  }
  &-table{
    flex: 1;
    padding: 5px 20px 20px;
  }
}
</style>