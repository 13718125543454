<template>
  <div class="Customers">
    <div class="board-title">客户申报数据榜单</div>
    <div class="Customers-table">
      <DataTable
        :columns="columns"
        :tableData="tableData"
        :visibleSize="3"
        :height="180"
      />
      <!-- <el-table
        :data="tableData"
        :highlight-current-row="false"
        size="small"
      >
        <el-table-column
          prop="name"
          label="客户名称"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="num"
          label="申报量"
          :width="100">
        </el-table-column>
        <el-table-column
          prop="totalPrice"
          label="申报金额"
          :width="150">
        </el-table-column>
      </el-table> -->
    </div>
  </div>
</template>

<script>
  import DataTable from '../DataTable.vue';
  export default {
    name: 'Customers',
    props: {
      queryDate: {
        type: Object,
        required: true,
      },
    },
    components: {
      DataTable,
    },
    data() {
      return {
        columns: [
          { label: '客户名称', prop: 'name' },
          { label: '申报量', prop: 'num' },
          { label: '申报金额(美元/万元)', prop: 'totalPrice' },
        ],
        tableData: []
      }
    },
    mounted () {
      this.getCustomerData();
    },
    methods: {
      async getCustomerData() {
        const res = await this.$request.get('/data/customerBusinessData/overallQuery', { params: this.queryDate});
        if(res.data && res.data.content.length) {
          this.tableData = res.data.content
            .filter(item => item.tradeMode === '9610')
            .map(item =>({
              name: item.customerName,
              num: item.num,
              totalPrice: (item.priceList ? item.priceList
                .filter(item => (item.tradeMode === "9610" && item.currencyName === '美元'))
                .reduce((prev, cur) => { prev += cur.totalPrice / 10000; return prev; }, 0) : 0).toFixed(3),
            }))
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.Customers{
  display: flex;
  flex-direction: column;
  .board-title{
    background-position: 33% 47%;
  }
  &-table{
    flex: 1;
    padding: 5px 20px 20px;
    ::v-deep(.el-table){
      background-color: transparent;
      tr{
        background-color: transparent;
        th.el-table__cell{
          background-color: transparent;
          border-bottom: 1px solid rgba(24, 122, 205, 0.88);
          .cell{
            color: rgba(25, 204, 219, 1);
          }
        }
      }
      .el-table__row{
        .el-table__cell{
          .cell{
            color: rgba(255, 255, 255, 1);
          }
        }
      }
      &.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
        background-color: transparent;
      }
      td.el-table__cell,
      th.el-table__cell.is-leaf{
        border-bottom: none;
      }
      .el-table__empty-text{
        color: #fff;
      }
      &::before{
        background-color: transparent;
      }
    }
  }
}
</style>