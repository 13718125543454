<template>
  <div class="DataTable">
    <div class="board-title">9610日数据报表</div>
    <div class="DataTable-table">
      <ScrollDataTable
        :columns="columns"
        :tableData="tableData"
      />
      <!-- <el-table
        ref="rw_table"
        style="height: 240px;"
        :data="tableData"
        :highlight-current-row="false"
        @cell-mouse-enter="mouseEnter"
        @cell-mouse-leave="mouseLeave"
      >
        <el-table-column
          prop="date"
          label="日期">
        </el-table-column>
        <el-table-column
          prop="trade9610"
          label="订单单量">
        </el-table-column>
        <el-table-column
          prop="count9610"
          label="清单单量">
        </el-table-column>
        <el-table-column
          prop="trade9710"
          label="出口（日元）">
        </el-table-column>
        <el-table-column
          prop="count9710"
          label="出口（美元）">
        </el-table-column>
        <el-table-column
          prop="trade9810"
          label="同比">
        </el-table-column>
        <el-table-column
          prop="count9810"
          label="环比">
        </el-table-column>
      </el-table> -->
    </div>
  </div>
</template>

<script>
  import * as dayjs from 'dayjs'
  import ScrollDataTable from '../DataTable.vue';
  export default {
    name: 'DataTable',
    components: {
      ScrollDataTable,
    },
    data() {
      return {
        queryParams: {
          startDate: dayjs().subtract(0, 'day').startOf('day').format("YYYY-MM-DD 00:00:00"),
          endDate: dayjs().subtract(0, 'day').endOf('day').format("YYYY-MM-DD 23:59:59"),
          tradeMode: '9610',
        },
        columns: [
          { label: '日期', prop: 'date' },
          { label: '订单单量', prop: 'orderNum' },
          { label: '清单单量', prop: 'inventoryNum' },
          { label: '出口（美元/万元）', prop: 'totalPrice美元', isFormat: 'num' },
          { label: '出口（日元/万元）', prop: 'totalPrice日本元', isFormat: 'num' },
          { label: '同比', prop: 'yoyRatio', icon: true, },
          { label: '环比', prop: 'momRatio', icon: true, },
        ],
        tableData: [],
      }
    },
    mounted () {
      this.getList();
    },
    methods: {
      async getList() {
        const res = await this.$request.get('/data/businessTypeData/listByDay', {
          params: this.queryParams
        });
        if(res.code === 200) {
          if(res.data && res.data.length) {
            this.tableData = res.data.map((item, index) => ({
              id: index,
              ...item,
              totalPriceUSD: 0,
              totalPriceJPY: 0,
              ...(
                item.priceList
                ?
                  item.priceList.reduce((prev, cur) => {
                    const { currency, totalPrice } = cur;
                    // let currencyShort = '';
                    // if(currency === '美元') {
                    //   currencyShort = 'USD';
                    // }
                    prev[`totalPrice${currency}`] = (totalPrice / 10000).toFixed(2);
                    return prev;
                  }, {})
                : {}
              )
            }))
          }
        } else{
          this.$message.error(res.msg);
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.DataTable{
  display: flex;
  flex-direction: column;
  .board-title{
    background-position: 43.5% 47%;
  }
  &-table{
    flex: 1;
    padding: 5px 20px 20px;
    ::v-deep(.el-table){
      background-color: transparent;
      tr{
        background-color: transparent;
        th.el-table__cell{
          background-color: transparent;
          border-bottom: 1px solid rgba(24, 122, 205, 0.88);
          .cell{
            color: rgba(25, 204, 219, 1);
          }
        }
      }
      .el-table__row{
        .el-table__cell{
          .cell{
            color: rgba(255, 255, 255, 1);
          }
        }
      }
      &.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
        background-color: transparent;
      }
      td.el-table__cell,
      th.el-table__cell.is-leaf{
        border-bottom: none;
      }
      .el-table__empty-text{
        color: #fff;
      }
      &::before{
        background-color: transparent;
      }
    }
  }
}
</style>