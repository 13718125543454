<template>
  <div class="Overview">
    <template v-for="(item, index) in businessConfig">
      <div :key="index" class="Overview-item">
        <div class="Overview-item-content">
          <div class="head">
            <span class="title">{{ item.name }}</span>
            <span class="unit">{{ item.unit }}</span>
          </div>
          <div class="con">
            <span v-if="item.unitAbb" class="abb">{{ item.unitAbb }}</span>
            <span class="number">{{ item.unitAbb ? formatNum(item.value) : item.value }}</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { formatNum } from '@/utils';
  export default {
    name: 'Overview',
    props: {
      queryDate: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        formatNum,
        businessConfig: [
          { name: '9610出口累计', unit: '金额（美元/万元）', unitAbb:'USD', value: 0 },
          { name: '9610出口累计', unit: '金额（日元/万元）', unitAbb:'JPY', value: 0 },
          { name: '9610申报客户', unit: '累计（家）', value: 0 },
          { name: '9610出口累计', unit: '累计（单）', value: 0 },
        ]
      }
    },
    mounted () {
      this.getDeclareData();
      this.getAmountData();
    },
    methods: {
      async getAmountData() {
        const res = await this.$request.get('/data/businessTypeData/tradeAmount', 
        {
          params: {
            ...this.queryDate,
            tradeMode: '9610'
          },
        });
        if(res.data && res.data['9610']) {
          const res9610 = res.data['9610'];
          res9610
            .filter(item => item.currency !== '人民币')
            .forEach(item => {
              if(item.currency === '美元') {
                this.businessConfig[0].value = item.totalPrice ? ((item.totalPrice / 10000).toFixed(3)) : 0;
              } else if(item.currency === '日本元'){
                this.businessConfig[1].value = item.totalPrice ? ((item.totalPrice / 10000).toFixed(3)) : 0;
              }
            });
        }
      },
      async getDeclareData() {
        const res = await this.$request.get('/data/customerBusinessData/declareTotalNum', 
        {
          params: {
            ...this.queryDate,
            tradeMode: '9610'
          },
        });
        if(res.data && res.data.content.length) {
          this.businessConfig[2].value = res.data.content.length;
          this.businessConfig[3].value = res.data.content.reduce((prev, cur) => {
            prev += cur.num;
            return prev;
          }, 0);
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.Overview{
  display: flex;
  justify-content: space-around;
  padding: 20px 10px;
  box-sizing: border-box;
  &-item {
    width: 315px;
    padding: 24px 24px;
    background: url('~@/assets/images/dataScreen/left_border_1.svg') center center no-repeat;
    box-sizing: border-box;
    &-content{
      padding: 25px 20px;
      background-color: rgba(24, 122, 205, 0.2);
      .head{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .title{
          color: rgba(255, 255, 255, 0.8);
        }
        .unit{
          color: rgba(46, 209, 238, 0.8);
        }
      }
      .con{
        display: flex;
        align-items: center;
        .abb{
          margin-right: 20px;
          color: rgba(255, 255, 255, 0.8);
        }
        .number{
          font-size: 24px;
          font-weight: 700;
          font-family: DINAlternate-Bold, DINAlternate;
          color: #E1B31F;
        }
      }
    }
  }
}
</style>