<template>
  <div class="Country">
    <div class="board-title">总贸易额&出口国</div>
    <div ref="DoubleRingRef" class="Country-chart"></div>
  </div>
</template>

<script>
  import $echartsConfig from '@/utils/echartsConfig';
  export default {
    name: 'Country',
    props: {
      queryDate: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        echartsView: null,
        pieOption: null,
        colors: [ '#376FE3', '#5026E4', '#8D25E4', '#DE343E', '#DF7221', '#E1B31F'],
      }
    },
    mounted () {
      this.initChart();
      this.geRatiotData();
    },
    methods: {
      initChart() {
        this.echartsView = $echartsConfig.echarts.init(this.$refs.DoubleRingRef);
        this.initChartOption();
      },
      initChartOption() {
        this.pieOption = {
          title: '',
          color: [ '#2E5EC1', '#4420C2', '#771FC2', '#BC2C34', '#BD611C', '#BF981A' ],
          tooltip: {
            trigger: 'item'
          },
          grid: {
            ...$echartsConfig.grid,
            right: 30,
          },
          legend: {
            ...$echartsConfig.legend,
            orient: 'vertical',
            top: 'middle',
            right: 24,
          },
          series: [
            {
              name: '业务类型申报量',
              type: 'pie',
              radius: ['20%', '30%'],
              center: ['45%', '50%'],
              label: {
                show: false,
              },
              labelLine: {
                show: false
              },
              emphasis: {
                disabled: true,
              },
              data: [],
            },
            {
              name: '业务类型申报量',
              type: 'pie',
              radius: ['30%', '50%'],
              center: ['45%', '50%'],
              label: {
                show: true,
                fontSize: 14,
                color: 'rgba(255, 255, 255, 1)',
                minMargin: 15,
                edgeDistance: 20,
                lineHeight: 15,
                formatter(param) {
                  return `${param.name}\n ${param.percent}%`;
                }
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              },
              data: [],
            },
          ],
        };
        this.echartsView.setOption(this.pieOption);
      },
      // 总贸易国占比
      async geRatiotData() {
        const resRatio = await this.$request.get('/data/countryBusinessData/ratio', {
          params: {
            ...this.queryDate,
            tradeMode: '9610'
          }
        });
        if(resRatio.data && resRatio.data.length) {
          const seriesData = resRatio.data
            .filter(item => item.tradeMode === '9610')
            .map((item, index) => ({
              name: item.countryName,
              value: item.num,
              itemStyle: {
                color: this.colors[index]
              }
            }));
          this.updateChartOption(seriesData);
        }
      },
      updateChartOption(seriesData){
        let option = {
          series: [
            {
              data: seriesData,
            },
            {
              data: seriesData.map((item, index) => ({
                ...item,
                itemStyle: {
                  color: this.colors[index]
                }
              })),
            }
          ]
        };
        this.echartsView.setOption(option, false);
      }
    },
  }
</script>

<style lang="scss" scoped>
.Country{
  width: 100%;
  height: 100%;
  .board-title{
    background-position: 33% 47%;
  }
  &-chart{
    height: 315px;
  }
}
</style>