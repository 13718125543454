<template>
  <div class="TodayDynamic">
    <div class="board-title">今日客户申报量</div>
    <div class="TodayDynamic-container">
      <div class="TodayDynamic-container-left">
        <bar-chart
          :legend="todayLegend"
          :colors="colors"
          :optionsData="todayOptions"
          barWidth="15%"
        />
      </div>
      <div class="TodayDynamic-container-right">
        <div class="item">
          <div class="item-top">
            <span class="label">今日申报总单量：</span>
            <span class="unit">单</span>
          </div>
          <div class="item-bottom value">
            {{ totalOptions.count }}
          </div>
        </div>
        <div class="item">
          <div class="item-top">
            <span class="label">今日申报金额：</span>
            <span class="unit">美元USD</span>
          </div>
          <div class="item-bottom">
            {{ formatNum(totalOptions.totalPriceUSD) }} 万元
          </div>
        </div>
        <div class="item">
          <div class="item-top">
            <span class="label">今日申报金额：</span>
            <span class="unit">日元JPY</span>
          </div>
          <div class="item-bottom">
            {{ formatNum(totalOptions.totalPriceJPY) }} 万元
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as dayjs from 'dayjs'
  import BarChart from '../BarLineChart.vue';
  import { formatNum } from '@/utils';
  export default {
    name: 'TodayDynamic',
    components: {
      BarChart,
    },
    data() {
      return {
        formatNum,
        queryDate: {
          startDate: dayjs().format("YYYY-MM-DD 00:00:00"),
          endDate: dayjs().format("YYYY-MM-DD 23:59:59"),
        },
        colors: [
          [ '#01BB7B', '#01BB7B' ],
          [ '#FF9502', '#FF9502' ],
        ],
        todayLegend:{
          num: '申报单量',
        },
        todayOptions: [],
        totalOptions: {
          count: 0,
          totalPriceUSD: 0,
          totalPriceJPY: 0,
        },
      }
    },
    mounted () {
      this.getDeclareData();
      this.getAmountData();
    },
    methods: {
      async getDeclareData() {
        const res = await this.$request.get('/data/customerBusinessData/declareTotalNum', {
          params: {
            ...this.queryDate,
            tradeMode: '9610'
          }
        });
        if(res.data && res.data.content.length) {
          this.todayOptions = res.data.content
          .filter(item => item.tradeMode === '9610')
          .map(item => ({
            name: item.customerName,
            num: item.num
          }));
        }
      },
      async getAmountData() {
        const res = await this.$request.get('/data/customerBusinessData/overallQuery', 
        {
          params: {
            ...this.queryDate,
            tradeMode: 9610,
          },
        });
        if(res.data && res.data.content.length) {
          const total = res.data.content.reduce((prev, cur) => {
            const { num, priceList }  = cur;
            prev.num += (num || 0);
            prev.usd += priceList ?
              priceList.find(price => price.currencyName === '美元')?.totalPrice
              : 0;
            prev.jpy += priceList ?
              priceList.find(price => price.currencyName === '日本元')?.totalPrice
              : 0;
            return prev;
          },{
            num: 0,
            usd: 0,
            jpy: 0
          });
          this.totalOptions.count = total.num;
          this.totalOptions.totalPriceUSD = total.usd ? total.usd / 10000 : 0;
          this.totalOptions.totalPriceJPY = total.jpy ? total.jpy / 10000 : 0;
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.TodayDynamic{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .board-title{
    background-position: 40% 47%;
  }
  &-container{
    // height: 100%;
    flex: 1;
    display: flex;
    justify-content: space-between;
    &-left{
      flex: 1;
    }
    &-right{
      width: 236px;
      padding: 12px 20px 20px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .item{
        width: 196px;
        padding: 10px 15px;
        margin-top: 12px;
        background-color: #05194E;
        border: 1px solid #2F3E78;
        box-sizing: border-box;
        &:first-child{
          margin-top: 0;
        }
        .label,
        .unit{
          padding: 2px 0;
          font-size: 12px;
          color: #fff;
        }
        .value{
          color: #34DBFC;
        }
        &-top,
        &-bottom{
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
        &-bottom{
          margin-top: 8px;
          color: #E1B31F;
        }
      }
      // &-top,
      // &-bottom{
      //   .title{
      //     margin-bottom: 9px;
      //     font-size: 12px;
      //     color: #2ED1EE;
      //   }
      //   .content{
      //     .top,
      //     .bottom{
      //       display: flex;
      //       justify-content: space-between;
      //       .item{
      //         width: 174px;
      //         padding: 9px 15px;
      //         background-color: #05194E;
      //         border: 1px solid #2F3E78;
      //         box-sizing: border-box;
      //         .label,
      //         .unit{
      //           padding: 2px 0;
      //           font-size: 12px;
      //           color: #fff;
      //         }
      //         .value{
      //           color: #34DBFC;
      //         }
      //         &-top,
      //         &-bottom{
      //           width: 100%;
      //           display: flex;
      //           justify-content: space-between;
      //         }
      //         &-bottom{
      //           margin-top: 8px;
      //           color: #E1B31F;
      //         }
      //       }
      //     }
      //     .top{
      //       .item{
      //         display: flex;
      //         justify-content: space-between;
      //       }
      //     }
      //     .bottom{
      //       margin-top: 10px;
      //     }
      //   }
      // }
      // &-bottom{
      //   margin-top: 20px;
      // }
    }
  }
}
</style>