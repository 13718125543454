<template>
  <div class="LastDays7">
    <div class="board-title">七日数据申报情况</div>
    <bar-chart
      :legend="todayLegend"
      :colors="colors"
      :optionsData="todayOptions"
      :barWidth="16"
    />
  </div>
</template>

<script>
  import * as dayjs from 'dayjs'
  import BarChart from '../BarLineChart.vue';
  export default {
    name: 'LastDays7',
    components: {
      BarChart,
    },
    data() {
      return {
        queryParams: {
          startDate: dayjs().subtract(7, 'day').startOf('day').format("YYYY-MM-DD 00:00:00"),
          endDate: dayjs().subtract(0, 'day').endOf('day').format("YYYY-MM-DD 23:59:59"),
          tradeMode: '9610',
        },
        colors: [
          [ '#60F5FF', '#3FA4FF' ],
          [ '#FF72A6', '#FF3657' ],
        ],
        todayLegend:{
          inventoryNum: '清单申报',
          orderNum: '订单申报',
        },
        todayOptions: [],
      }
    },
    mounted () {
      this.getList();
    },
    methods: {
      async getList() {
        const res = await this.$request.get('/data/businessTypeData/listByDay', {
          params: this.queryParams
        });
        if(res.code === 200) {
          if(res.data && res.data.length) {
            this.todayOptions = res.data.reverse().map(item => ({
              name: item.date,
              orderNum: item.orderNum,
              inventoryNum: item.inventoryNum,
            }))
          }
        } else{
          this.$message.error(res.msg);
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.LastDays7{
  width: 100%;
  height: 100%;
  .board-title{
    background-position: 33% 47%;
  }
}
</style>