<template>
  <DataScreenBox>
    <div class="Dashboard9610">
      <Header title="9610报关动态数据统计分析" :curDate="curDate" />
      <div class="main">
        <div class="main-left">
          <div class="main-left-top">
            <Overview :queryDate="queryDate" />
          </div>
          <div class="main-left-center">
            <div class="main-left-center-left">
              <TodayDynamic />
            </div>
            <div class="main-left-center-right">
              <LastDays7 />
            </div>
          </div>
          <div class="main-left-bottom">
            <DataTable />
          </div>
        </div>
        <div class="main-right">
          <div class="main-right-top">
            <Country
              :queryDate="queryDate"
            />
          </div>
          <div class="main-right-center">
            <Pass :queryDate="queryDate" />
          </div>
          <div class="main-right-bottom">
            <Customers :queryDate="queryDate" />
          </div>
        </div>
      </div>
    </div>
  </DataScreenBox>
</template>

<script>
  import * as dayjs from 'dayjs'
  import DataScreenBox from '@/components/DataScreenBox.vue';
  import Header from '../Header.vue';
  import Overview from './Overview.vue';
  import TodayDynamic from './TodayDynamic.vue';
  import LastDays7 from './LastDays7.vue';
  import DataTable from './DataTable.vue';
  import Country from './Country.vue';
  import Pass from './Pass.vue';
  import Customers from './Customers.vue';
  export default {
    name: 'Dashboard9610',
    components: {
      DataScreenBox,
      Header,
      Overview,
      TodayDynamic,
      LastDays7,
      DataTable,
      Country,
      Pass,
      Customers,
    },
    data() {
      return {
        curDate: dayjs().format("YYYY-MM-DD"),
        queryDate: {
          startDate: dayjs('2020-01-01').startOf().format("YYYY-MM-DD 00:00:00"),
          endDate: dayjs().endOf().format("YYYY-MM-DD 23:59:59"),
        },
        todayLegend:{
          order: '订单回执',
          list: '清单回执',
        },
        todayOptions: [],
        todayTotalOptions: {
          orderDeclarationIn: 123,
          orderDeclared: 123,
          orderDeclarationUsd: 34537538.98,
          orderDeclarationJpy: 34537538.98,
          listDeclarationIn: 123,
          listDeclared: 123,
          listDeclarationUsd: 34537538.98,
          listDeclarationJpy: 34537538.98,
        },
        businessOptions: [],
        countryOptions: {
          seriesData: []
        },
      }
    },
    mounted () {
      this.getData();
    },
    methods: {
      getData() {
        setTimeout(() => {
          this.countryOptions.seriesData = [
            { value: 1048, name: '美国' },
            { value: 735, name: '加拿大' },
            { value: 580, name: '日本' },
            { value: 484, name: '韩国' },
            { value: 674, name: '英国' },
            { value: 100, name: '其他' },
          ];
          this.todayOptions = [
            { name: '电子口岸申报中', order: 123, list: 89 },
            { name: '海关入口', order: 423, list: 189 },
            { name: '人工审核', order: 323, list: 489 },
            { name: '海关审核', order: 73, list: 99 },
            { name: '海关放行', order: 173, list: 199 },
            { name: '结关', order: 173, list: 199 },
          ]
          this.businessOptions = [
            { name: '威海德航', export9610: 123, export9710: 89, export9810: 577 },
            { name: '青岛一番', export9610: 423, export9710: 189, export9810: 277 },
            { name: '新海丰', export9610: 323, export9710: 489, export9810: 77 },
            { name: '青岛联力', export9610: 73, export9710: 99, export9810: 577},
            { name: '联宇天翼', export9610: 173, export9710: 199, export9810: 377 },
            { name: '山东捷丰', export9610: 173, export9710: 199, export9810: 177 },
            { name: '山东泛中', export9610: 173, export9710: 199, export9810: 97 },
          ]
        }, 500)
      }
    },
  }
</script>

<style lang="scss" scoped>
.Dashboard9610{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .main{
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: space-between;
    &-left{
      width: 1332px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-top{
        width: 100%;
        height: 166px;
      }
      &-center{
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 400px;
        &-left{
          width: 792px;
          background: url('~@/assets/images/dataScreen/left_border_2.svg') center center no-repeat;
        }
        &-right{
          width: 528px;
          background: url('~@/assets/images/dataScreen/left_border_3.svg') center center no-repeat;
        }
      }
      &-bottom{
        width: 100%;
        height: 334px;
        background: url('~@/assets/images/dataScreen/left_border_4.svg') center center no-repeat;
      }
    }
    &-right{
      width: 528px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-top{
        height: 364px;
        background: url('~@/assets/images/dataScreen/right_border_1.svg') center center no-repeat;
      }
      &-center{
        height: 277px;
        background: url('~@/assets/images/dataScreen/right_border_2.svg') center center no-repeat;
      }
      &-bottom{
        height: 277px;
        background: url('~@/assets/images/dataScreen/right_border_2.svg') center center no-repeat;
      }
      
    }
  }
}
</style>